<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">Customers</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="javascript:void(0);">Shoplattice</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Customers</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-title">
            <div class="row">
              <div class="col-12 col-md-3">
                <form action="#" class="search-field">
                  <div class="d-flex align-items-center input-group">
                    <div class="input-group-prepend bg-transparent">
                      <i class="input-group-text border-0 mdi mdi-magnify"></i>
                    </div>
                    <input
                      type="text"
                      class="form-control bg-transparent border-0"
                      placeholder="Search...."
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="table-responsive mb-0">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Customer Name</th>
                  <th>Mobile</th>
                  <th>Email</th>
                  <th>Wallet</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Muhammed</td>
                  <td>9876543210</td>
                  <td>mohammed@codelattice.com</td>
                  <td>
                   123
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Text</td>
                  <td>9876543210</td>
                  <td>admin@cl.com</td>
                  <td>
                   234
                  </td>
                </tr>
              </tbody>
            </table>
            <ul class="pagination pagination-rounded justify-content-end m-3">
              <li class="page-item disabled">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  aria-label="Previous"
                >
                  <i class="mdi mdi-chevron-left"></i>
                </a>
              </li>
              <li class="page-item active">
                <a class="page-link" href="javascript: void(0);">1</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript: void(0);">2</a>
              </li>
              <li class="page-item">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  aria-label="Next"
                >
                  <i class="mdi mdi-chevron-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "customers",
  components: {},
  data() {
    return {};
  },
};
</script>

<style scoped></style>
